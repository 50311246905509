import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

export default function DisplayModal({ handleClose }) {
  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="sync-modal-title"
        aria-describedby="sync-modal-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "white",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            padding: "2rem",
          }}
        >
          <Typography variant="h4" fontFamily={"InterBold"} gutterBottom>
            Your events have been synced!
          </Typography>
          <Typography variant="body2" fontFamily={"Inter"} gutterBottom>
            Your events will now show up in the calendar.
          </Typography>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            style={{ marginTop: "1rem" }}
          >
            Sync Another Syllabus
            <ArrowForward />
          </Button>
        </div>
      </Modal>
    </div>
  );
}
