export default function TermsOfService() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        minHeight: "100vh",
        padding: "2rem",
        textAlign: "left",
      }}
    >
      <h1>Terms of Service</h1>

      <p>Last updated: March 27, 2024</p>

      <p>
        SyncSylla ("us", "we", or "our") operates the website
        https://syncsylla.com (the "Service").
      </p>

      <p>
        By accessing or using the Service, you agree to be bound by these Terms.
        If you disagree with any part of the terms, then you may not access the
        Service.
      </p>

      <h2>1. Access to the Service</h2>

      <ul>
        <li>
          Access to SyncSylla's software is granted to the user upon a quarterly
          payment, providing unlimited access to the product. This includes the
          ability to upload Syllabus's (1 pdf for the 1st Tier plan, and
          unlimited for the 2nd tier plan).
        </li>
        <li>Users can refund within 7 days.</li>
      </ul>

      <h2>2. Use of Our Application</h2>

      <p>
        You agree to use our application only for lawful purposes and in
        accordance with these Terms. You are responsible for maintaining the
        confidentiality of your account credentials.
      </p>

      <h2>3. Intellectual Property</h2>

      <p>
        Our application and its original content, features, and functionality
        are owned by us and are protected by international copyright, trademark,
        patent, trade secret, and other intellectual property or proprietary
        rights laws.
      </p>

      <h2>4. Limitation of Liability</h2>

      <p>
        We shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues,
        whether incurred directly or indirectly, or any loss of data, use,
        goodwill, or other intangible losses.
      </p>

      <h2>5. Governing Law</h2>

      <p>
        These Terms shall be governed and construed in accordance with the laws
        of Canada, without regard to its conflict of law provisions.
      </p>

      <h2>6. Changes to These Terms</h2>

      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will provide at least
        30 days' notice prior to any new terms taking effect.
      </p>

      <h2>7. Contact Us</h2>

      <p>
        If you have any questions about these Terms, please contact us at
        yegorzaytsevbusiness@gmail.com
      </p>
    </div>
  );
}
