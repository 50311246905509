import React from "react";
import ReactDOM from "react-dom";
import { Route, Routes } from "react-router-dom";
import EmailLandingPage from "./pages/EmailLandingPage";
import LiveLandingPage from "./pages/LiveLandingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import TermsOfService from "./pages/TermsOfService";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" exact element={<LiveLandingPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/tos" element={<TermsOfService />} />
      </Routes>
    </div>
  );
};

export default App;
