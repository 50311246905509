import { Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ProductList from "./ProductList";
import "../../index.css";
import { ArrowForward, Check } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const theme = useTheme();
  const navigate = useNavigate();
  const benefits = [
    "Finally take control of your time",
    "Schedule your whole semester in minutes",
    "No more stress about forgetting a deadline",
  ];
  const isSmallScreen = useMediaQuery("(max-width: 899px)");
  return (
    <div
      style={{
        display: "flex",
        padding: isSmallScreen ? "5rem 0 " : "10rem 0",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Typography
        align="center"
        variant="h2"
        style={{
          paddingTop: "2rem",
          color: theme.palette.text.secondary,
          fontFamily: "InterBold",
          // fontWeight: "bolder",
        }}
      >
        Never miss a deadline again.
      </Typography>
      <Typography
        variant="h5"
        align="center"
        color="primary-text"
        style={{ paddingBottom: "2rem" }}
      >
        Sync your syllabus with your calendar in seconds, so you can finally
        study with peace of mind.
      </Typography>
      <ProductList
        justifyContent={"center"}
        benefits={benefits}
        IconComponent={<Check color="success" sx={{ marginRight: "0.5rem" }} />}
      />
      <Button
        variant="contained"
        style={{ marginTop: "1rem" }}
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        <Typography>Sync Your First Syllabus FREE</Typography>
        <ArrowForward />
      </Button>
    </div>
  );
}
