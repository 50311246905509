import React from "react";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

export default function ProductList({
  justifyContent,
  benefits,
  IconComponent,
}) {
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: justifyContent,
        padding: "0 0",
      }}
    >
      <List disablePadding>
        {benefits.map((benefit, index) => (
          <ListItem key={index} disablePadding>
            {IconComponent}
            <Typography style={{ fontFamily: "Inter" }} variant="body">
              {benefit}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
