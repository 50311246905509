import React from "react";
import { Typography, Grid, Paper } from "@mui/material";

const Description = ({ imgSrc, title, description }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        margin: "1rem",
        minHeight: "300px",
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Grid item>
          <Typography variant="h5" sx={{ fontFamily: "Inter" }}>
            {title}
          </Typography>
          <Typography variant="h7" sx={{ fontFamily: "Inter" }}>
            {description}
          </Typography>
        </Grid>
        <Grid item sx={{ paddingTop: { md: "0.5rem" } }}>
          <img
            src={imgSrc}
            alt="Man Applying For Job"
            style={{ maxWidth: "100%", maxHeight: "300px" }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Description;
