import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import axios from "axios";
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";
import "@fontsource/inter/600.css";

// Check if React DevTools are present
if (window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
  // Disable React DevTools by overwriting its functions
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {};
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inlineRenderers = [];
}

axios.interceptors.request.use((request) => {
  return request;
});

let theme = createTheme({
  palette: {
    primary: {
      main: "#0288D1",
    },
    accent: {
      main: "#f0f0f0",
    },
    secondaryAccent: {
      main: "#9575CD",
    },
    text: {
      main: "#FFFFFF",
      secondary: "#212121",
    },
  },
});
theme = responsiveFontSizes(theme);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
