import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Upload from "./Upload";
import DisplayEvents from "./DisplayEvents/DisplayEvents";
import { Button } from "@mui/material";
import Calendar from "./Calendar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuth0 } from "@auth0/auth0-react";
import useEventContext from "../../hooks/useEventContext";

const steps = [
  { title: "Upload Your Syllabus", component: <Upload /> },
  { title: "Edit Your Events", component: <DisplayEvents /> },
  { title: "Sync Your Calendar", component: <Calendar /> },
];

export default function SyllabusStepper() {
  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth0();
  const [activeStep, setActiveStep] = useState(0);
  const { events } = useEventContext();

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ marginBottom: "1rem" }}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              onClick={() => setActiveStep(index)}
              style={{ cursor: "pointer" }}
            >
              {step.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {React.cloneElement(steps[activeStep].component, {
        onNextStep: handleNextStep,
        onPreviousStep: handlePreviousStep,
        setActiveStep: setActiveStep,
      })}
    </Box>
  );
}
