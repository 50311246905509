import { React, useState } from "react";
import useEventContext from "../../hooks/useEventContext";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuth0 } from "@auth0/auth0-react";
import PremiumButton from "../shared/PremiumButton";
import AlertMsg from "../shared/AlertMsg";
import DisplayModal from "../shared/DisplayModal";

// Mock Google Calendar View Component
export default function Calendar({ setActiveStep }) {
  const { events, getColorHex } = useEventContext();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth0();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  function closeAlert() {
    setAlertMsg("");
  }
  function closeModal() {
    setIsModalOpen(false);
    setActiveStep(0);
  }

  // Function to display events for a specific date
  const renderEventsForDate = (date) => {
    const eventStartDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    ); // Start of the day
    const eventEndDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59,
      999
    ); // End of the day

    const eventsForDate = events.filter((event) => {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);

      // Check if event starts and ends on the same day
      const isSameDay = startDate <= eventEndDate && endDate >= eventStartDate;

      // Compare event start and end dates with the start and end of the current day
      return (
        isSameDay || (startDate <= eventEndDate && endDate >= eventStartDate)
      );
    });

    return eventsForDate.map((event, index) => {
      return (
        <div
          key={index}
          style={{
            position: "absolute",
            width: "100%",
            borderRadius: "5px",
            padding: "2px",
            fontSize: "0.8rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            backgroundColor: getColorHex(event.colorId),
            left: 0,
            fontFamily: "Inter",
            color: "white",
          }}
        >
          {event.title}
        </div>
      );
    });
  };

  const monthYearString = currentDate.toLocaleString(undefined, {
    month: "long",
    year: "numeric",
  });

  // Mock data for current month
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const daysArray = Array.from(
    { length: daysInMonth },
    (_, index) => new Date(currentYear, currentMonth, index + 1)
  );

  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => {
      return new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1);
    });
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => {
      return new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1);
    });
  };

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  // Create an array of day names
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // Shift the day names array based on the first day of the month
  const shiftedDayNames = [
    ...dayNames.slice(firstDayOfMonth),
    ...dayNames.slice(0, firstDayOfMonth),
  ];

  const handleCall = async () => {
    try {
      const response = await axiosPrivate.get(
        `Calendar/auth/callback/${user.sub}`
      );

      const payload = {
        userId: user.sub,
        events: events,
        accessToken: response.data.access_token,
      };

      const response2 = await axiosPrivate.post(
        "Calendar/upload/google",
        payload
      );

      if (response2.status === 200) {
        setIsModalOpen(true);
      } else {
        setAlertMsg(response2.statusText);
        setSeverity("warn");
      }
    } catch (error) {
      // Error handling for both responses
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error("Response error:", error.response.data);
        setAlertMsg(error.response.statusText);
        setSeverity("warn");
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
        setAlertMsg("No response received from the server");
        setSeverity("warn");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Request setup error:", error.message);
        setAlertMsg("Error in setting up request");
        setSeverity("warn");
      }
    }
  };

  return (
    <div>
      <div
        style={{
          gridArea: "1 / 1 / 2 / 8",
          textAlign: "center",
          padding: "10px",
          borderBottom: "1px solid #ccc",
          fontWeight: "bold",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ArrowBack
            onClick={goToPreviousMonth}
            style={{ cursor: "pointer" }}
          />
          <Typography fontFamily={"InterBold"} variant="h5">
            {monthYearString}
          </Typography>
          <ArrowForward onClick={goToNextMonth} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "1px",
          border: "1px solid #ccc",
        }}
      >
        {shiftedDayNames.map((dayName, index) => (
          <div
            key={index}
            style={{
              position: "relative", // Ensure events are positioned relative to the cell
              padding: "10px",
              height: "40px", // Adjust height as needed
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            <div>{dayName}</div>
          </div>
        ))}
        {daysArray.map((date) => (
          <div
            key={date}
            style={{
              position: "relative",
              padding: "10px",
              border: "1px solid #ccc",
              height: "90px",
              overflow: "hidden",
            }}
          >
            <div>{date.toLocaleDateString(undefined, { day: "numeric" })}</div>
            <div>{renderEventsForDate(date)}</div>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <PremiumButton
          buttonText={"Sync Events"}
          handleClick={handleCall}
          style={{ marginTop: "0.5rem" }}
        />
      </div>
      {isModalOpen && <DisplayModal handleClose={closeModal} />}
      {alertMsg && (
        <AlertMsg
          alertMsg={alertMsg}
          severity={severity}
          closeAlert={closeAlert}
        />
      )}
    </div>
  );
}
