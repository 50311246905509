import React, { useState } from "react";
import { Tabs, Tab, Box, Grid, useTheme, Typography } from "@mui/material";
import Description from "./Description";
import { useMediaQuery } from "@mui/material";
import EmailSignUp from "./EmailSignUp";

const steps = [
  {
    imgSrc: "/sync_account.png",
    title: "Connect Calendar Account",
    description:
      "1 Minute account sync where all of your due dates will be populated.",
  },
  {
    imgSrc: "/edit_events.png",
    title: "Upload And Confirm The Events",
    description:
      "Customize the data for each event before syncing, such as adding reminders, extra info etc.",
  },
  {
    imgSrc: "/event_preview.png",
    title: "Preview Your Selection",
    description:
      "Before syncing, get a preview of your calendar and double check any changes.",
  },
  {
    imgSrc: "/uploaded_event.png",
    title: "Sync your Syllabus",
    description:
      "Submit your preferences and sync all your due dates to your calendar.",
  },
];

const TabPanel = ({ value, index, children }) => (
  <div hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

export default function ProductDescription() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        padding: { xs: "1rem", md: "5rem" },
        // backgroundColor: theme.palette.accent.main,
      }}
    >
      <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
        <Typography variant="h3" gutterBottom fontWeight="bold">
          Auto-Sync Syllabus to Calendar
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
        {/* TabPanel components */}
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Description {...steps[0]} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Description {...steps[1]} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Description {...steps[2]} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Description {...steps[3]} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          orientation="horizontal"
          centered
          sx={{
            marginTop: "1rem",
            "& .MuiTabs-indicator": { display: "none" },
            "& .Mui-selected": {
              fontWeight: "bold",
              fontSize: "18px",
            },
          }}
        >
          <Tab label="Step 1" />
          <Tab label="Step 2" />
          <Tab label="Step 3" />
          <Tab label="Step 4" />
        </Tabs>
      </Grid>
    </Grid>
  );
}
