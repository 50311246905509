export default function PrivacyPolicy() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        minHeight: "100vh",
        padding: "2rem",
        textAlign: "left", // Aligns text content to the left
      }}
    >
      <h1>Privacy Policy</h1>

      <p>Last updated: March 9, 2024</p>

      <p>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you use our SaaS application.
      </p>

      <h2>Information We Collect</h2>

      <p>
        We collect information that you provide directly when using our
        application. This may include:
      </p>

      <ul>
        <li>Personal information (e.g., name, email address)</li>
        <li>Usage data (e.g., pages visited, features used)</li>
        <li>Device information (e.g., device type, operating system)</li>
      </ul>

      <h2>How We Use Your Information</h2>

      <p>We use the information collected to:</p>

      <ul>
        <li>Provide and maintain our SaaS application</li>
        <li>Improve and personalize user experience</li>
        <li>Respond to customer support requests</li>
        <li>Send periodic emails (e.g., newsletters, updates)</li>
      </ul>

      <h2>Sharing Your Information</h2>

      <p>We do not share or sell and of your data.</p>

      <h2>Security</h2>

      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access or disclosure.
      </p>

      <h2>Limited Use Policy</h2>

      <p>
        SyncSylla's use and transfer to any other app of information received
        from Google APIs will adhere to{" "}
        <a href="https://developers.google.com/terms/api-services-user-data-policy">
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </p>

      <h2>Changes to This Privacy Policy</h2>

      <p>
        We may update this Privacy Policy from time to time. The latest version
        will be posted on this page with the updated date.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at yegorzaytsevbusiness@gmail.com.
      </p>
    </div>
  );
}
