import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";

export default function AuthButton({ text, variant, color, redirectPath }) {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  async function handleSignInAndRedirect() {
    if (!isAuthenticated) {
      try {
        await loginWithRedirect({
          access_type: "offline",
          appState: {
            returnTo: redirectPath,
          },
        });
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  }

  return (
    <div>
      <Button variant={variant} color={color} onClick={handleSignInAndRedirect}>
        <Typography style={{ fontFamily: "InterBold" }}>{text}</Typography>
      </Button>
    </div>
  );
}
