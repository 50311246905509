import { createContext, useState } from "react";

const EventContext = createContext({});

export const EventProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);

  function createNewEvent() {
    const newEvent = {
      start: "2024-03-17T12:30:00", // Example start time: 12:30 PM
      end: "2024-03-17T14:00:00", // Example end time: 2:00 PM
      title: "New Event",
      reminders: [],
      comments: "",
      colorId: "1",
    };
    const newEvents = [...events, newEvent];
    setEvents(newEvents);
  }

  function deleteEvent() {
    if (selectedEventIndex >= 0 && selectedEventIndex < events.length) {
      console.log("Index: " + selectedEventIndex);
      events.splice(selectedEventIndex, 1);
      console.log(
        "Event at index",
        selectedEventIndex,
        "deleted successfully."
      );
    } else {
      console.log("Invalid index. No event deleted.");
    }
  }

  function updateStartAndEndDate({ eventIndex, startDate, endDate }) {
    const newEvents = [...events];
    newEvents[eventIndex] = {
      ...newEvents[eventIndex],
      start: startDate,
      end: endDate,
    };
    setEvents(newEvents);
  }

  function updateReminder({ eventIndex, reminderDate }) {
    console.log(eventIndex);
    const newEvents = [...events];
    const updatedEvent = newEvents[eventIndex];
    updatedEvent.reminders = [...updatedEvent.reminders, reminderDate];
    newEvents[eventIndex] = updatedEvent;
    setEvents(newEvents);
  }

  const colorIdToHexMap = {
    1: "#7986cb", // Lavender
    2: "#33b679", // Sage
    3: "#8e24aa", // Grape
    4: "#e67c73", // Flamingo
    5: "#f6c026", // Banana
    6: "#f5511d", // Tangerine
    7: "#039be5", // Peacock
    8: "#616161", // Graphite
    9: "#3f51b5", // Blueberry
    10: "#0b8043", // Basil
    11: "#d60000", // Tomato
  };

  const getColorHex = (colorId) => colorIdToHexMap[colorId];

  function updateEventProperty({ eventIndex, property, value }) {
    console.log("Updating: " + property + " to " + value);
    const newEvents = [...events];
    newEvents[eventIndex] = {
      ...newEvents[eventIndex],
      [property]: value,
    };
    setEvents(newEvents);
  }
  function updateTime(eventIndex, time, timeField) {
    // Extract the existing date part from the event object
    let isGreaterThanEnd = false; // Initialize variable to track if start time is greater than end time
    const existingDate = new Date(events[eventIndex][timeField]);
    const year = existingDate.getFullYear();
    const month = existingDate.getMonth() + 1; // Month is 0-indexed
    const day = existingDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    // Check if the start time is greater than the end time
    if (
      timeField === "start" &&
      events[eventIndex][timeField] > events[eventIndex]["end"]
    ) {
      isGreaterThanEnd = true;
    }

    // Concatenate the existing date with the updated time
    const formattedDateTime = `${formattedDate}T${time}:00`;

    console.log("New Time: " + formattedDateTime);
    // Update events array with the new date-time value
    const newEvents = [...events];
    newEvents[eventIndex] = {
      ...newEvents[eventIndex],
      [timeField]: formattedDateTime,
    };

    // If start time is greater than end time, update the end time as well
    if (isGreaterThanEnd) {
      newEvents[eventIndex] = {
        ...newEvents[eventIndex],
        end: formattedDateTime,
      };
    }
    setEvents(newEvents);
  }

  return (
    <EventContext.Provider
      value={{
        events,
        setEvents,
        updateReminder,
        updateTime,
        updateEventProperty,
        createNewEvent,
        deleteEvent,
        setSelectedEventIndex,
        getColorHex,
        colorIdToHexMap,
        updateStartAndEndDate,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
