import { useMediaQuery } from "@mui/material";
import { Alert, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function AlertMsg({ alertMsg, closeAlert, severity }) {
  const isSmallScreen = useMediaQuery("(max-width: 899px)");
  return (
    <div
      style={{
        position: "fixed",
        top: 10,
        width: isSmallScreen ? "90%" : "35%",
        left: isSmallScreen ? "5%" : "35%",
        zIndex: 1000,
      }}
    >
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={closeAlert}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        {alertMsg}
      </Alert>
    </div>
  );
}
