import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Typography, Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import AlertMsg from "./AlertMsg";

export default function PremiumButton({
  buttonText,
  disabledConditions,
  handleClick,
  style,
}) {
  const [uploadStatus, setUploadStatus] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/users/uploadstatus/${user.sub}`
        );
        if (response.status === 200) {
          const data = response.data;
          console.log(data);
          setUploadStatus(data);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  async function handleCall() {
    setIsLoading(true);
    await handleClick();
    setIsLoading(false);
  }

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        disabled={!uploadStatus || disabledConditions}
        onClick={handleCall}
        style={style}
      >
        {isLoading ? <CircularProgress color="inherit" /> : buttonText}
      </Button>
      {!uploadStatus && (
        <Typography style={{ color: "red", marginTop: 10 }}>
          {isAuthenticated ? "You have ran out of credits" : "Please Login"}
        </Typography>
      )}
    </div>
  );
}
