import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Button,
  ListItem,
  List,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import useEventContext from "../../../hooks/useEventContext";
import { format } from "date-fns";
import "./Event.css";

export default function Event({
  event,
  index,
  setReminderMsg,
  isSelected,
  onClick,
}) {
  const {
    events,
    updateReminder,
    updateTime,
    setSelectedEventIndex,
    updateEventProperty,
    getColorHex,
    colorIdToHexMap,
    updateStartAndEndDate,
  } = useEventContext();
  const [daysInAdvance, setDaysInAdvance] = useState(1);
  const theme = useTheme();

  const handleDaysInAdvanceChange = (event) => {
    setDaysInAdvance(event.target.value);
  };

  const formatDate = (dateString) => {
    var formattedDate = format(dateString, "MMMM do, yyyy");
    return formattedDate;
  };

  function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  function formatTextDate(dateString) {
    // Check if the date is valid
    const date = new Date(dateString);
    if (!date) return ""; // Return an empty string if date is null or undefined

    // Get year, month, and day from the date object
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0"); // Months are zero-based
    let day = date.getDate().toString().padStart(2, "0");

    // Return the formatted date string in "YYYY-MM-DD" format
    return `${year}-${month}-${day}`;
  }

  function formatReminder(eventDate, reminderDays) {
    const reminderDate = new Date(eventDate);
    reminderDate.setDate(reminderDate.getDate() - reminderDays);
    return formatDate(reminderDate.toISOString());
  }

  function handleStartDate(event, index) {
    const currentStartTime =
      new Date(events[index].start).getHours() +
      ":" +
      new Date(events[index].start).getMinutes().toString().padStart(2, "0");
    const currentEndTime =
      new Date(events[index].end).getHours() +
      ":" +
      new Date(events[index].end).getMinutes().toString().padStart(2, "0");
    const startDate = new Date(event.target.value);
    const endDate = new Date(events[index].end);

    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1; // Month is 0-indexed
    const day = startDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (startDate >= endDate) {
      updateStartAndEndDate({
        eventIndex: index,
        startDate: `${formattedDate}T${currentStartTime}`,
        endDate: `${formattedDate}T${currentEndTime}`,
      });
    } else {
      updateEventProperty({
        eventIndex: index,
        property: "start",
        value: `${formattedDate}T${currentStartTime}`,
      });
    }
  }

  const handleReminderSet = (eventIndex) => {
    setReminderMsg("Reminder Set");
    setTimeout(() => {
      setReminderMsg(null);
    }, 4000);
    updateReminder({
      eventIndex: eventIndex,
      reminderDate: daysInAdvance,
    });
  };
  return (
    <Accordion
      className={`event ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <AccordionSummary
        expandIcon={<ExpandMore style={{ color: theme.palette.text.main }} />}
        style={{
          backgroundColor: getColorHex(event.colorId),
          fontFamily: "InterBold",
          color: theme.palette.text.main,
        }}
        onClick={(e) => setSelectedEventIndex(index)}
      >
        {event.title} - {formatDate(event.start)}
      </AccordionSummary>
      <AccordionDetails
        style={{
          fontFamily: "Inter",
          minHeight: "15rem",
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid item md={4}>
            <div style={{ padding: "0 0.5rem", marginTop: "1rem" }}>
              <TextField
                value={event.title}
                label="Event Name"
                variant="outlined"
                onChange={(e) =>
                  updateEventProperty({
                    eventIndex: index,
                    property: "title",
                    value: e.target.value,
                  })
                }
                fullWidth
              ></TextField>
            </div>
          </Grid>
          <Grid item md={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0 1rem",
              }}
            >
              <Typography
                style={{ fontFamily: "Inter", paddingBottom: "0.5rem" }}
              >
                Days in Advance:
              </Typography>
              <Select
                value={daysInAdvance}
                onChange={handleDaysInAdvanceChange}
                variant="outlined"
                style={{
                  minWidth: "6rem",
                  height: "2rem",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7].map(
                  (day) =>
                    !event.reminders.includes(day) && (
                      <MenuItem key={day} value={day}>{`${day} day${
                        day !== 1 ? "s" : ""
                      }`}</MenuItem>
                    )
                )}
              </Select>
              <Button
                onClick={() => {
                  handleReminderSet(index);
                }}
                variant="contained"
                style={{
                  marginTop: "0.5rem",
                  backgroundColor: getColorHex(event.colorId),
                }}
              >
                Add Reminder
              </Button>
            </div>
          </Grid>
          <Grid item md={4} padding={"0 1rem"}>
            <List disablePadding>
              <Typography fontFamily={"Inter"}>Reminders</Typography>
              {event.reminders.length > 0 ? (
                event.reminders.map((reminder, reminderIndex) => (
                  <ListItem key={reminderIndex} disablePadding>
                    - {formatReminder(event.start, reminder)}
                  </ListItem>
                ))
              ) : (
                <Typography>N/A</Typography>
              )}
            </List>
          </Grid>
          <Grid
            item
            sm={6}
            md={4}
            display={"flex"}
            sx={{
              flexDirection: { xs: "column", md: "column" },
            }}
          >
            <div
              className="time-settings"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 0.5rem",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    paddingBottom: "0.5rem",
                  }}
                >
                  Start Time:
                </Typography>
                <TextField
                  type="time"
                  value={formatTime(event.end)}
                  onChange={(e) => updateTime(index, e.target.value, "start")}
                  variant="outlined"
                  sx={{
                    minWidth: "10rem",
                    height: "2rem",
                    marginBottom: { xs: "1rem" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 0.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    paddingBottom: "0.5rem",
                  }}
                >
                  End Time:
                </Typography>
                <TextField
                  type="time"
                  value={formatTime(event.end)}
                  onChange={(e) => updateTime(index, e.target.value, "end")}
                  variant="outlined"
                  style={{
                    minWidth: "10rem",
                    height: "2rem",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
            <div
              className="date-settings"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 0.5rem",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    paddingBottom: "0.5rem",
                  }}
                >
                  Start Date:
                </Typography>
                <TextField
                  type="date"
                  value={formatTextDate(event.start)}
                  onChange={(e) => handleStartDate(e, index)}
                  variant="outlined"
                  sx={{
                    minWidth: "6rem",
                    height: "2rem",
                    marginBottom: { xs: "1rem" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 0.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    paddingBottom: "0.5rem",
                  }}
                >
                  End Date:
                </Typography>
                <TextField
                  type="date"
                  value={formatTextDate(event.end)}
                  onChange={(e) =>
                    updateEventProperty({
                      eventIndex: index,
                      property: "end",
                      value:
                        formatTextDate(e.target.value) +
                        "T" +
                        formatTime(event.end),
                    })
                  }
                  variant="outlined"
                  style={{
                    minWidth: "6rem",
                    height: "2rem",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: formatTextDate(event.start),
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            md={8}
            padding={"1rem 1rem"}
            sx={{ marginTop: { xs: "1rem" } }}
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Comments"
              value={event.comments}
              onChange={(e) =>
                updateEventProperty({
                  eventIndex: index,
                  property: "comments",
                  value: e.target.value,
                })
              }
            ></TextField>
          </Grid>
          <Grid
            item
            md={4}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              padding: "1rem",
            }}
          >
            {Object.entries(colorIdToHexMap).map(([colorId, hex]) => (
              <div
                key={colorId}
                style={{
                  backgroundColor: hex,
                  width: "1.5rem",
                  height: "1.5rem",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  margin: "4px",
                  borderRadius: "4px",
                }}
                onClick={() =>
                  updateEventProperty({
                    eventIndex: index,
                    property: "colorId",
                    value: colorId,
                  })
                }
              ></div>
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
