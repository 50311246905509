import { EventProvider } from "../context/EventContext";
import SyllabusStepper from "../components/Dashboard/SyllabusStepper";
import NavBar from "../components/shared/NavBar";

export default function Dashboard() {
  return (
    <EventProvider>
      <NavBar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <SyllabusStepper />
      </div>
    </EventProvider>
  );
}
