import { Typography, Button } from "@mui/material";
import { useState } from "react";
import useEventContext from "../../../hooks/useEventContext";
import AlertMsg from "../../shared/AlertMsg";
import Event from "../Event/Event";
import "./DisplayEvents.css";
import { ArrowForward } from "@mui/icons-material";

export default function DisplayEvents({ onNextStep }) {
  const { events, createNewEvent, deleteEvent } = useEventContext();
  const [selectedEventIndex, setSelectedEventIndex] = useState(-1);
  const [reminderMsg, setReminderMsg] = useState(null);

  async function handleDeleteEvent() {
    setSelectedEventIndex(-1);
    await deleteEvent();
  }

  function closeReminder() {
    setReminderMsg("");
  }

  return (
    <div>
      <div style={{ padding: "1rem 5rem", height: "65vh", overflowY: "auto" }}>
        {events.map((event, index) => (
          <Event
            event={event}
            index={index}
            setReminderMsg={setReminderMsg}
            isSelected={index === selectedEventIndex}
            onClick={() => setSelectedEventIndex(index)}
          />
        ))}
      </div>
      {reminderMsg && (
        <AlertMsg
          alertMsg={reminderMsg}
          closeAlert={closeReminder}
          severity={"success"}
        />
      )}
      <div className="event-list" style={{ marginTop: "1rem" }}>
        <Button
          variant="contained"
          onClick={handleDeleteEvent}
          className="delete-event"
        >
          Delete Event{" "}
          {selectedEventIndex > -1 ? `#${selectedEventIndex + 1}` : ""}
        </Button>
        <Button
          variant="contained"
          onClick={createNewEvent}
          className="add-event"
        >
          Add Event
        </Button>
        <Button
          variant="contained"
          onClick={onNextStep}
          style={{ marginLeft: "1rem" }}
        >
          Submit Changes
          <ArrowForward />
        </Button>
      </div>
    </div>
  );
}
