// Footer.js
import React from "react";
import { Typography, Link, List, ListItem, Grid } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router";

export default function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      display={"flex"}
      flexDirection={"row"}
      container
      style={{
        backgroundColor: theme.palette.primary.main,
        padding: "2rem",
        paddingTop: "0rem",
        marginTop: "2rem",
        color: "white",
      }}
      spacing={3}
    >
      <Grid item>
        <Typography variant="body1" align="left" fontFamily={"Inter"}>
          SyncSylla
        </Typography>
        <Typography
          variant="body2"
          color="inherit"
          align="left"
          paragraph
          fontFamily={"Inter"}
        >
          Sync your syllabus to your calendar in seconds so you never worry
          about a deadline again.
        </Typography>
        <Typography variant="body3" fontFamily={"Inter"}>
          Copyright © 2024 - All rights reserved
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" align="left">
          <List>
            LEGAL
            <ListItem disablePadding>
              <span
                color="inherit"
                onClick={() => navigate("/privacypolicy")}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent",
                  transition: "border-color 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.borderColor = "currentColor";
                }}
                onMouseLeave={(e) => {
                  e.target.style.borderColor = "transparent";
                }}
              >
                Privacy Policy
              </span>
            </ListItem>
            <ListItem disablePadding>
              <span
                color="inherit"
                onClick={() => navigate("/tos")}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent",
                  transition: "border-color 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.borderColor = "currentColor";
                }}
                onMouseLeave={(e) => {
                  e.target.style.borderColor = "transparent";
                }}
              >
                Terms of Service
              </span>
            </ListItem>
          </List>
        </Typography>
      </Grid>
    </Grid>
  );
}
