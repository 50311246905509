import { Grid, Typography, Paper } from "@mui/material";
import BenefitList from "./ProductList";
import { useTheme } from "@mui/material";
import { Check, Close } from "@mui/icons-material";

export default function USP() {
  const theme = useTheme();
  const before = [
    "Time-consuming, error-prone calendar updates.",
    "Juggling diverse deadlines without automation.",
    "Forgetting updates leads to missed deadlines.",
  ];
  const after = [
    "Instant, error-free calendar updates.",
    "Seamless syncing without manual management.",
    "Timely reminders, avoiding deadline neglect.",
  ];

  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: { xs: "1rem", md: "5rem" },
      }}
    >
      <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
        <Typography variant="h3" gutterBottom sx={{ fontFamily: "InterBold" }}>
          Constantly Feeling Behind?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            outline: `5px solid #D32F2F`,
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ fontFamily: "Inter" }}>
            Without SyncSylla
          </Typography>

          <BenefitList
            justifyContent={"left"}
            benefits={before}
            IconComponent={
              <Close color="error" sx={{ marginRight: "0.5rem" }} />
            }
          />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            outline: `5px solid #388E3C`,
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontFamily: "InterBold" }}
          >
            With SyncSylla
          </Typography>

          <BenefitList
            justifyContent={"left"}
            benefits={after}
            IconComponent={
              <Check color="success" sx={{ marginRight: "0.5rem" }} />
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
