import AuthButton from "./AuthButton";
import Box from "@mui/material/Box";
import { AppBar, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileMenu from "./ProfileMenu";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";

export default function NavBar() {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <img
            src="/chinchilla.png"
            alt="Your Logo"
            onClick={() => {
              navigate("/");
            }}
            style={{
              maxWidth: "100%",
              padding: "0.5rem",
              cursor: "pointer",
              maxHeight: "5rem",
            }}
          />
          <Typography
            variant="h4"
            sx={{ fontFamily: "InterBold", marginRight: "auto " }}
          >
            SyncSylla
          </Typography>

          {isAuthenticated ? (
            <ProfileMenu />
          ) : (
            <AuthButton
              text={"Login"}
              color="inherit"
              variant="none"
              redirectPath="/"
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
