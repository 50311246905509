import React, { useState } from "react";
import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useEventContext from "../../hooks/useEventContext";
import { useAuth0 } from "@auth0/auth0-react";
import PremiumButton from "../shared/PremiumButton";

export default function Upload({ onNextStep }) {
  const { setEvents } = useEventContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleFileChange = (event) => {
    console.log("setting file");
    const file = event.target.files[0];
    setSelectedFile(file);
    // onNextStep();
  };
  useEffect(() => {
    const mockData = [
      {
        start: "2024-03-01T12:30:00",
        end: "2024-03-01T13:00:00",
        title: "Quiz 1 Due",
        reminders: [],
        comments: "",
        colorId: "1",
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        start: "2024-03-31T12:30:00",
        end: "2024-03-31T14:00:00",
        title: "Computer Science Midterm",
        reminders: [],
        comments: "",
        colorId: "1",
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    ];

    setEvents(mockData);
  }, []);

  const handleUpload = async () => {
    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("SyllabusFile", selectedFile);

      const response = await axiosPrivate.post(
        "/Document/processPDF",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = response.data;
      if (data) {
        // Map the data structure if it exists
        const mappedData = data.parsedEvents.map((item) => ({
          title: item.title,
          start: item.start,
          end: item.end,
          reminders: item.reminders,
          comments: item.comments,
          colorId: item.colorId,
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }));
        setEvents(mappedData);
        onNextStep();
        alert("Uploaded!");
      } else {
        alert("Invalid response");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "#f0f0f0",
          margin: "1rem 10rem",
          padding: "1rem",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Select Your Syllabus
        </Typography>
        <input
          accept="application/pdf"
          style={{ display: "none" }}
          id="pdf-file-input"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="pdf-file-input">
          <Button variant="contained" component="span">
            Select PDF
          </Button>
        </label>

        {selectedFile && (
          <Typography variant="body1" style={{ marginTop: 10 }}>
            Selected File: {selectedFile.name}
          </Typography>
        )}
        <PremiumButton
          disabledConditions={!selectedFile || uploading}
          buttonText={"Upload"}
          handleClick={handleUpload}
          style={{ marginTop: "1rem" }}
        />
      </Paper>
    </div>
  );
}
