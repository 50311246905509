import React, { useEffect, useState } from "react";
import NavBar from "../components/shared/NavBar";
import "../index.css";
import "../design/Homepage.css";
import Hero from "../components/LandingPage/Hero";
import ProductDescription from "../components/LandingPage/ProductDescription";
import Pricing from "../components/LandingPage/Pricing";
import USP from "../components/LandingPage/USP";
import Footer from "../components/LandingPage/Footer";
import ActionButton from "../components/LandingPage/ActionButton";
import { useAuth0 } from "@auth0/auth0-react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function LiveLandingPage() {
  const { isAuthenticated, user } = useAuth0();
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [isAuthenticated]);
  async function getUser() {
    await axiosPrivate.post("api/users/getUser", user.sub);
  }
  return (
    <div>
      <NavBar />
      <Hero />
      <USP />
      <ProductDescription />
      {/* <Pricing /> */}
      <Footer />
    </div>
  );
}
